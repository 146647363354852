.layoutContainer{
    padding: 1em;
    border-top: 2px solid #d1d1d1;
}
.contentContainer{
    height: 100vh;
    margin-top: -154px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    
    
}
.contentLimiter{
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 60%;
    min-width: 300px;
}
.contentBtnArea{
    display: flex;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
    padding: unset !important;
}

.contentBtnArea> button{
    margin: .5em;    
}

.contentBtnArea .bk{
    margin: 1em;
}
.contentBox{
    border: 2px solid #d1d1d1;
    padding: 1em;
    width: 100%;
}
.contentBox.success{
    border-color: #399e2a;
    
    
}
.contentBox.success .is-disabled{
    border-color: unset;
}
.contentBox.error{
    border-color: #f36e6e;
}
.contentBox> div{
    padding: .5em 0;
}
.termsCondition{
    max-width: 500px;
    padding: 1em;
    white-space: pre-line;
}