.LoginContainer{
    height: 100vh;
    margin-top: -94px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
   

}
.LoginLimiter{
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 60%;
    min-width: 300px;
}
.LoginItem{
   width: 100%;
}