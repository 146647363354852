.HeadLogo{
  height: 60px;

}
header{
  padding: 1em;
  background-color: white;
  border-bottom: 2px solid #a8a8a8;
  
}
.headContainer{
    display: flex;
    align-items: center;
}
.navStack{
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
