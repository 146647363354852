.bookingName{
    font-size: 24px;
    padding: .5em;
    display: flex;
    flex-grow: 100000000001;
    flex-direction: row;
}
.bookingStub{
    font-size: 24px;
    padding: .5em;
    
}
.button-primary-color{
    background-color:#399e2a;
    border-color:#399e2a;
    color: white;
}
.button-primary-color:hover{
    background-color:#19660d;
    border-color:#19660d;
    color: white;
    
}
.button-primary-error{
    background-color:#dc3545;
    border-color:#dc3545;
    color: white;
}
.button-primary-error:hover{
    background-color:#a12a36;
    border-color:#a12a36;
    color: white;
    
}
.button-primary-color.is-disabled{
    background-color: rgb(243, 242, 241) !important;
    border-color: rgb(243, 242, 241) !important;
    color: rgb(161, 159, 157) !important;
}
.spacing{
    padding: .3em;

}
.spacing:last-of-type{
    padding-right: 1em;
}

.bookingHeaderContainer{
    display: flex;
    flex-wrap: wrap;
}
.bookingSaldoWrapper{
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    flex-grow: 1;
    
    /* align-items: flex-end; */
}
/* 
@media only screen and (max-width: 600px) {
    .OrderInternalLine{
        flex-direction: column;
        align-items: flex-start;
    }
    .OrderLinePair   {
        width: 100%;
    }
  } */