.DayOrderContainer{
    padding: 1em;
    border-top: 2px solid #d1d1d1;
    position: relative;
}
.DayOrderspinner{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #00000014;
    padding: 50px 100px;
    border-radius: 10px;
    z-index: 1;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.38);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.38);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.38);

}
.DayOrderContainer:last-of-type{
    border-bottom: 2px solid #d1d1d1;
}
.OrderInternalLine{
    display: flex;
    align-items: center;

    width: 100%;


}
.OrderLineLocked{
    color: red;
}
.OrderLinePair{
    width: 50%;
    display: flex;
    align-items: center;
 
    
    
}

.ms-Toggle-background:disabled{
    background-color: unset;
    
}
.ms-Toggle.is-checked .ms-Toggle-background{
    background-color: #399e2a;
    
}
.OrderlineItemselected{
    color: #399e2a;
    font-weight: bold;
}
.OrderLinePair:first-of-type {
    padding-left: unset;

}
.OrderLinePair:last-of-type{
    padding-right: unset;

}
.OrderlineItem{
    min-width: 250px;
    width: 33%;
    padding: .5em;
}
.OrderlineItem:first-of-type{
    /* padding-left: unset; */
}
.OrderDate{
    font-size: 1.3em;
    padding-bottom: .8em;
}
.OrderDate::first-letter{
    text-transform: capitalize;
}
.OrderDish{
    font-size: 1.2em;
    font-weight: 400;

}
@media only screen and (max-width: 600px) {
    .OrderlineItem{
        width: 100%;
    }
    .OrderInternalLine{
        flex-direction: column;
        align-items: flex-start;
    }
    .OrderLinePair   {
        width: 100%;
    }
  }